/* eslint-disable no-unused-expressions */
// ライブラリの読み込みなどを行うためTOKIUMのプロジェクト全体で読み込む
// 実際の処理はこのファイルには記述しない

import { getUserLocale } from './utilities/locale';

require('@tokium/design-token');

const jquery = require('jquery');

global.jQuery = jquery;
global.$ = jquery;

require('bootstrap');
require('bootstrap-datepicker');
require('jquery-validation-engine/js/jquery.validationEngine.min.js');
require('core-js/stable');
require('regenerator-runtime/runtime');
require('smart-app-banner');

const locale = getUserLocale();

if (locale === 'en') {
  require('jquery-validation-engine/js/languages/jquery.validationEngine-en.js');
} else {
  require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min.js');
  require('jquery-validation-engine/js/languages/jquery.validationEngine-ja.js');
}

if (!global.Intl) {
  import(/* webpackChunkName: "intl" */ 'intl');
  import(/* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/en.js');
  import(/* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/ja.js');
}
